import React from 'react'
import { graphql } from 'gatsby'
import { MetaTagsMustHave } from '../components/seo'
import { PageLayoutDefault } from '../components/layout'
import { PostContent, parsePostContent } from '../components/posts'

const Page = ({ data }) => {
  const { page } = data.wp

  return (
    <>
      <MetaTagsMustHave {...page.seo} />
      <PageLayoutDefault heading={page.title}>
        <PostContent>
          {parsePostContent(page.content)}
        </PostContent>
      </PageLayoutDefault>
    </>
  )
}

export default Page

export const pageQuery = graphql`
  query PageById($uri: ID!) {
    wp {
      page(
        id: $uri
        idType: URI
      ) {
        title
        content
        seo {
          ...SeoFields
        }
      }
    }
  }
`
